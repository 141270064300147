import capitalize from 'lodash/capitalize'

/**
 * @template {Record<string, string | number>} T
 * @typedef {T[keyof T]} EnumType
 */

export const isProductionEnv = process.env.REACT_APP_ENV === 'production'
export const APP_PREFIX = '@travelledger'
export const BASE_URL =
  process.env.REACT_APP_ENV === 'local' ? `${window.location.origin}` : `${window.location.origin}/api`
export const API_URL = `${BASE_URL}/v1`
export const FTP_HOST = process.env.REACT_APP_FTP_SERVER_HOST ?? '0.0.0.0'
export const FTP_PORT = process.env.REACT_APP_FTP_SERVER_PORT ?? 22

// feature flags that are not set in the env will default to false
export const FEATURE_PAGINATION_ENABLED = process.env.REACT_APP_FEATURE_PAGINATION_ENABLED === 'true'
export const FEATURE_TL_SUB_ID_ENABLED = process.env.REACT_APP_FEATURE_TL_SUB_ID_ENABLED === 'true'
export const FEATURE_ALLOW_SETTLEMENT_OF_UNREVIEWED =
  process.env.REACT_APP_FEATURE_ALLOW_SETTLEMENT_OF_UNREVIEWED === 'true'

export const USER_AUTH_STORAGE_KEYS = {
  id: '@tl:id',
  token: '@tl:token',
  email: '@tl:email',
  username: '@tl:username',
  address: '@tl:address',
  isAdmin: '@tl:isAdmin',
  companyId: '@tl:companyId',
  name: '@tl:name',
  role: '@tl:role',
}

export const ADMIN_VIEWS = ['/admin', '/tlpay']

export const ROUTE_URL = {
  home: '/',
  notifications: '/notifications',
  notificationsConfiguration: '/notifications/configuration',
  login: '/login',
  setupAuthenticator: '/authenticator/setup',
  resetAuthenticator: '/authenticator/reset',
  confirmAuthenticator: '/authenticator/confirm',
  registration: '/registration',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  createTransaction: '/new-transaction',
  createBookingTransaction: '/new-transaction/booking',
  createRemittanceTransaction: '/new-transaction/remittance',
  receivedTransactions: '/received',
  csv: '/file-uploads',
  fileDataDelivery: '/data-delivery',
  taskScheduler: '/scheduler',
  admin: '/admin',
  user: '/user',
  invite: '/invite',
  companyInvite: '/invite/company',
  userById: '/user/:id',
  bookingsReview: '/bookings',
  remittancesReview: '/remittances',
  paymentsStatement: '/payments-statement',
  instantPayments: '/instant-payments',
  createBranch: '/my-company/new-branch',
  entityProfile: '/my-company',
  userProfile: '/user-profile',
  businessTermsList: '/business-terms',
  businessTermsCreate: '/business-terms/new',
  businessTermsEdit: '/business-terms/edit/:id',
  paymentProfileList: '/payment-profiles',
  paymentProfileConfiguration: '/paymentProfileConfiguration',
  simulationTool: '/simulation',
  commercialLinkDetails: '/commercial-links/detail',
  commercialLinkBulkCreation: '/commercial-links/bulk',
  commercialLinksList: '/commercial-links',
  settlementSchedulesList: '/settlementSchedules',
  settlementScheduleEdit: '/settlementSchedules/edit/:id?',
  maintenanceMode: '/maintenance',
  billingInvoices: '/billing/invoices',
  billingDetails: '/billing/details',
  billingSubscription: '/billing/subscription',
  companiesList: '/companies',
  tlpayAccounts: '/tlpay/accounts',
  tlpayOpenNewAccount: '/tlpay/accounts/new',
  tlpayNiumSignup: '/tlpay/:companyId/accounts/nium/new',
  tlpayRevolutSignup: '/tlpay/:companyId/accounts/revolut/new',
  tlpayRevolutSignupComplete: '/tlpay/accounts/revolut/signups/:signupId/complete',
}

export const COMPANY_ID_TYPES = {
  ABTA: 'ABTA',
  IATA: 'IATA',
  TIDS: 'TIDS',
  EMAIL_ID: 'EMAIL_ID',
}

export const READ_ONLY_COMPANY_ID_TYPES = {
  ABTACRM: 'ABTACRM',
  TL_S: 'TL_S',
}

/** __NOTE__ invoice statuses */
export const STATUSES = {
  NA: 'N/A',
  MATCHED: 'MATCHED',
  AMENDED: 'AMENDED',
  REJECTED: 'REJECTED',
  ACCEPTED: 'ACCEPTED',
  VOID: 'VOID',
  FILE_DUPLICATE: 'FILE_DUPLICATE',
  TRANS_DUPLICATE: 'TRANS_DUPLICATE',
}

export const INVOICE_STATUSES_SELLER_TO_NAME = {
  [STATUSES.MATCHED]: capitalize(STATUSES.MATCHED),
  [STATUSES.ACCEPTED]: capitalize(STATUSES.ACCEPTED),
  [STATUSES.AMENDED]: capitalize(STATUSES.AMENDED),
  [STATUSES.REJECTED]: capitalize(STATUSES.REJECTED),
  [STATUSES.VOID]: capitalize(STATUSES.VOID),
}

export const INVOICE_STATUSES_BUYER_TO_NAME = {
  [STATUSES.MATCHED]: 'New',
  [STATUSES.ACCEPTED]: capitalize(STATUSES.ACCEPTED),
  [STATUSES.AMENDED]: capitalize(STATUSES.AMENDED),
  [STATUSES.REJECTED]: capitalize(STATUSES.REJECTED),
  [STATUSES.VOID]: capitalize(STATUSES.VOID),
}

export const INVOICE_STATUSES_TO_NAME = {
  ...INVOICE_STATUSES_BUYER_TO_NAME,
  ...INVOICE_STATUSES_SELLER_TO_NAME,
}

export const INVOICE_EDIT_STATUS_OPTIONS = Object.keys(INVOICE_STATUSES_BUYER_TO_NAME).filter(
  (status) => ![STATUSES.MATCHED, STATUSES.VOID].includes(status),
)

export const SPS_IMPORT_FILE_STATUSES = {
  [STATUSES.MATCHED]: capitalize(STATUSES.MATCHED),
  [STATUSES.FILE_DUPLICATE]: 'File Duplicate',
  [STATUSES.TRANS_DUPLICATE]: 'Trans Duplicate',
  [STATUSES.NA]: 'Not matched',
}

/** Record<RemittanceStatus, READABLE> */
export const REMITTANCE_STATUSES = {
  SUBMITTED: 'Submitted',
  COMPLETED: 'Completed',
  REJECTED: 'Rejected',
  CLEARING: 'Clearing',
}
/** @typedef {keyof REMITTANCE_STATUSES} RemittanceStatus */

export const PAYMENTS_STATUSES = {
  SCHEDULED: 'Scheduled',
  SUBMITTED: 'Submitted',
  COMPLETED: 'Completed',
}

export const ACTIVE_COLLECTIONS_STATUSES = {
  TOTAL: 'Total',
  SCHEDULED: 'Scheduled',
  PENDING: 'Pending',
}

export const COMMERCIAL_LINK_STATUS = {
  /** @type {'LIVE'}      */ LIVE: 'LIVE',
  /** @type {'PENDING'}   */ PENDING: 'PENDING',
  /** @type {'SUSPENDED'} */ SUSPENDED: 'SUSPENDED',
  /** @type {'ARCHIVED'}  */ ARCHIVED: 'ARCHIVED',
}
/** @typedef {EnumType<COMMERCIAL_LINK_STATUS>} CommercialLinkStatus */

export const CL_STATUS_TO_NAME = {
  [COMMERCIAL_LINK_STATUS.LIVE]: capitalize(COMMERCIAL_LINK_STATUS.LIVE),
  [COMMERCIAL_LINK_STATUS.PENDING]: capitalize(COMMERCIAL_LINK_STATUS.PENDING),
  [COMMERCIAL_LINK_STATUS.SUSPENDED]: capitalize(COMMERCIAL_LINK_STATUS.SUSPENDED),
  [COMMERCIAL_LINK_STATUS.ARCHIVED]: capitalize(COMMERCIAL_LINK_STATUS.ARCHIVED),
}

// NOTE probably better named: CL_STATUS_FILTERS
export const CL_STATUS_KEY_TITLE = {
  ALL: '(all)',
  ...CL_STATUS_TO_NAME,
}

export const CL_ROLE_TO_NAME = {
  SELLER: 'Seller',
  BUYER: 'Buyer',
}

// NOTE probably better named: CL_ROLE_FILTERS
export const CL_ROLE_KEY_TITLE = {
  ALL: '(all)',
  ...CL_ROLE_TO_NAME,
}

export const TRANSACTION_TYPE_TO_NAME = {
  0: 'Invoice',
  1: 'Credit Note',
  2: 'Remittance',
  3: 'Commission Invoice',
}

export const ALLOCATABLE_TRANSACTIONS = [TRANSACTION_TYPE_TO_NAME[2], TRANSACTION_TYPE_TO_NAME[3]]

export const TRANSACTION_STATE_TO_NAME = {
  0: 'OUTSTANDING',
  1: 'PART_SETTLED',
  2: 'SETTLED',
  3: 'ERRORED',
}

export const TRANSACTION_STATE_TO_CSS = {
  [TRANSACTION_STATE_TO_NAME[0]]: 'is-warning',
  [TRANSACTION_STATE_TO_NAME[1]]: 'is-info',
  [TRANSACTION_STATE_TO_NAME[2]]: 'is-success',
  [TRANSACTION_STATE_TO_NAME[3]]: 'is-danger',
}

export const CURRENCY_CODE = {
  GBP: 826,
  USD: 840,
  EUR: 978,
  CHF: 756,
}

export const CURRENCY_CODE_TO_NAME = {
  [CURRENCY_CODE.GBP]: 'GBP',
  [CURRENCY_CODE.USD]: 'USD',
  [CURRENCY_CODE.EUR]: 'EUR',
  [CURRENCY_CODE.CHF]: 'CHF',
}

export const CURRENCY = {
  GBP: 'GBP',
  USD: 'USD',
  EUR: 'EUR',
  CHF: 'CHF',
}

export const KEYS = {
  escape: 27,
}

export const SORT_ICON = {
  [-1]: 'fas fa-arrow-right',
  0: 'fas fa-arrow-up',
  1: 'fas fa-arrow-down',
}

export const REASON_CODE_TO_DESCRIPTION = {
  A: 'Not yet due',
  B: 'Already Paid',
  C: 'Incorrect ABTA number',
  D: 'Agent not enabled',
  E: 'Cancelled Booking',
  F: 'Additional Requirements',
  G: 'Duplicate Transaction',
  H: 'Invalid Commission Calculated',
  I: 'Part Payment',
  J: 'Non-payment by client',
  K: 'Wrongly Assigned',
  L: 'Awaiting amendment invoices',
  M: 'Re-instatement',
  N: 'Buyer not present',
  O: 'Commercial Link missing/not active',
  P: 'Payment Validation Failure',
  Q: 'Record Cancelled by Seller',
  S: 'Amended By System',
  T: 'Paid by customer',
  X: 'Failed BACS submission',
  W: 'Failed Wallet Settlement',

  // Commented out for 1.12 release
  // // See: https://zube.io/applied-blockchain/travel-ledger/c/3472
  // // TL Codes - Financial
  // '00': 'Incorrect deposit calculation',
  // '01': 'Incorrect Trans amount',
  // '02': 'Incorrect VAT amount',
  // '03': 'Incorrect Sell amount',
  // '04': 'Incorrect Currency',
  // '05': 'Future Credit applied',
  // '06': 'Rounding Error',
  // '07': 'Balance Paid Early',
  // // TL Codes - Misc
  // '20': 'Incorrect Conf number',
  // '21': 'Other',
}

export const VALID_REASONS_TO_AMEND = {
  A: 'Not yet due',
  B: 'Already Paid',
  C: 'Incorrect ABTA number',
  D: 'Agent not enabled',
  E: 'Cancelled Booking',
  F: 'Additional Requirements',
  G: 'Duplicate Transaction',
  H: 'Invalid Commission Calculated',
  I: 'Part Payment',
  J: 'Non-payment by client',
  K: 'Wrongly Assigned',
  L: 'Awaiting amendment invoices',
  M: 'Re-instatement',
  T: 'Paid by customer',

  // Commented out for 1.12 release
  // '00': 'Incorrect deposit calculation',
  // '01': 'Incorrect Trans amount',
  // '02': 'Incorrect VAT amount',
  // '03': 'Incorrect Sell amount',
  // '04': 'Incorrect Currency',
  // '05': 'Future Credit applied',
  // '06': 'Rounding Error',
  // '07': 'Balance Paid Early',
  // '20': 'Incorrect Conf number',
  // '21': 'Other',
}

export const VALID_REASONS_TO_REJECT = VALID_REASONS_TO_AMEND

export const PERIOD_KEY_TITLE = {
  AFTER_BOOKING: 'After Booking',
  BEFORE_DEPARTURE: 'Before Departure',
}

export const FINANCIAL_FLOAT_OPTIONS = {
  SMART: 'Smart',
  PE: 'Processing Entity',
}

/** @deprecated does not take into account translations, see useMemoWeekdayItems */
export const SCHEDULE_DAY_KEY_TITLE = {
  1: 'MONDAY',
  2: 'TUESDAY',
  3: 'WEDNESDAY',
  4: 'THURSDAY',
  5: 'FRIDAY',
  6: 'SATURDAY',
  0: 'SUNDAY',
}

/** @deprecated does not take into account translations, see useMemoWeekdayItems */
export const SCHEDULE_DAY_ABBREVIATION_KEY_TITLE = {
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
  0: 'Sun',
}

export const NOTIFICATION_TYPES = {
  ALERT: 'alert',
  MESSAGE: 'message',
  WARNING: 'warning',
}
export const NOTIFICATION_MODULES = {
  SYSTEM: 'System',
  FTP_DATA_DELIVERY: 'FTP Data Delivery',
  EMAIL_DATA_DELIVERY: 'Email Data Delivery',
  PAYMENTS: 'Payments',
  INVOICE_IMPORTER: 'Invoice Importer',
  REPORT_SCHEDULER: 'Report Scheduler',
}

export const DEFAULT_EMAIL_NOTIFY_FOR = Object.values(NOTIFICATION_MODULES).reduce((acc, cur) => {
  acc[cur] = {
    [NOTIFICATION_TYPES.MESSAGE]: false,
    [NOTIFICATION_TYPES.WARNING]: false,
    [NOTIFICATION_TYPES.ALERT]: true,
  }
  return acc
}, {})

export const DEFAULT_PLATFORM_NOTIFY_FOR = DEFAULT_EMAIL_NOTIFY_FOR

export const TRANSACTION_TEMPLATE = 'BookingAll'
export const COMPANIES_TEMPLATE = 'Companies'

export const SCHEDULE_AVAILABLE_TEMPLATES = {
  SellerRec: 'Basic Seller Rec - Standard',
  SellerRec8DigitTRA: 'Basic Seller Rec - 8 Digit TRA',
  SellerRecFailures: 'Seller Rec Failures - Standard',
  SellerRecFailures8DigitTRA: 'Seller Rec Failures- 8 Digit TRA',
  BuyerRec: 'Buyer Back-Office Rec File',
  [TRANSACTION_TEMPLATE]: 'Transactions',
  RemittancesAll: 'Remittances',
  [COMPANIES_TEMPLATE]: 'Companies',
}

export const SCHEDULE_SPS_FILE_FORMAT_KEY_TITLE = {
  sps: 'SPS',
}

export const SCHEDULE_FILE_FORMAT_KEY_TITLE = {
  csv: 'CSV',
}

export const TASK_CRITERIA = {
  /** @type {'TODAY'}                   */ Today: 'TODAY',
  /** @type {'YESTERDAY'}               */ Yesterday: 'YESTERDAY',
  /** @type {'CURRENT_WEEK'}            */ CurrentWeek: 'CURRENT_WEEK',
  /** @type {'CURRENT_MONTH'}           */ CurrentMonth: 'CURRENT_MONTH',
  /** @type {'LAST_CALENDAR_WEEK'}      */ LastCalendarWeek: 'LAST_CALENDAR_WEEK',
  /** @type {'LAST_7_DAYS'}             */ Last7Days: 'LAST_7_DAYS',
  /** @type {'LAST_CALENDAR_MONTH'}     */ LastCalendarMonth: 'LAST_CALENDAR_MONTH',
  /** @type {'LAST_3_CALENDAR_MONTHS'}  */ Last3CalendarMonths: 'LAST_3_CALENDAR_MONTHS',
  /** @type {'LAST_CALENDAR_YEAR'}      */ LastCalendarYear: 'LAST_CALENDAR_YEAR',
  /** @type {'ALL_RECORDS'}             */ AllRecords: 'ALL_RECORDS',

  // specific to TaskCriteriaType.CollectionSubmission
  /** @type {'LAST_COLLECTION'}         */ LastCollection: 'LAST_COLLECTION',

  // specific to TaskTemplate.BuyerRec
  /** @type {'BUYER_REC_CURRENT_WEEK'}  */ BuyerRecCurrentWeek: 'BUYER_REC_CURRENT_WEEK',
  /** @type {'BUYER_REC_ALL_DUE'}       */ BuyerRecAllDue: 'BUYER_REC_ALL_DUE',
  /** @type {'CUSTOM'}                  */ Custom: 'CUSTOM',
}
/** @typedef {EnumType<typeof TASK_CRITERIA>} TaskCriteria */

export const TASK_CRITERIA_TITLE = {
  [TASK_CRITERIA.Today]: 'Today',
  [TASK_CRITERIA.Yesterday]: 'Yesterday',
  [TASK_CRITERIA.CurrentWeek]: 'This Week',
  [TASK_CRITERIA.CurrentMonth]: 'This Month',
  [TASK_CRITERIA.LastCalendarWeek]: 'Last calendar week',
  [TASK_CRITERIA.Last7Days]: 'Last 7 days',
  [TASK_CRITERIA.LastCalendarMonth]: 'Last calendar month',
  [TASK_CRITERIA.Last3CalendarMonths]: 'Last 3 calendar months',
  [TASK_CRITERIA.LastCalendarYear]: 'Last calendar year',
  [TASK_CRITERIA.AllRecords]: 'All Records',
  [TASK_CRITERIA.LastCollection]: 'Last Collection',
  [TASK_CRITERIA.BuyerRecCurrentWeek]: 'Current Week',
  [TASK_CRITERIA.BuyerRecAllDue]: 'All Due',
  [TASK_CRITERIA.Custom]: 'Custom',
}

export const TASK_TRANSACTIONS_CRITERIA_TYPES = {
  COLLECTION_SUBMISSION: 'COLLECTION_SUBMISSION',
  COMMIT_DATE: 'COMMIT_DATE',
}

export const TASK_SELLER_REC_TEMPLATES = [
  'SellerRec',
  'SellerRec8DigitTRA',
  'SellerRecFailures',
  'SellerRecFailures8DigitTRA',
]

export const TASK_BUYER_REC_TEMPLATES = ['BuyerRec']

export const TASK_TRANSACTIONS_TEMPLATES = [...TASK_SELLER_REC_TEMPLATES, TRANSACTION_TEMPLATE]

export const BSD_CALENDAR_EVENTS_ACTIONS = {
  SKIP_COLLECTION: 'SKIP_COLLECTION',
  UN_SKIP_COLLECTION: 'UN_SKIP_COLLECTION',
  UN_SKIP_CYCLE_START: 'UN_SKIP_CYCLE_START',
  FORCE_COLLECTION: 'FORCE_COLLECTION',
  SKIP_CYCLE_START: 'SKIP_CYCLE_START',
  FORCE_CYCLE_START: 'FORCE_CYCLE_START',
  RESCHEDULE_FORCE_COLLECTION: 'RESCHEDULE_FORCE_COLLECTION',
  RESCHEDULE_FORCE_CYCLE_START: 'RESCHEDULE_FORCE_CYCLE_START',
}

export const BSD_CALENDAR_EVENTS = {
  WEEKEND: 'WEEKEND',
  HOLIDAY: 'HOLIDAY',
  BSD: 'BSD',
  SCHEDULED_COLLECTION: 'SCHEDULED_COLLECTION',
  SCHEDULED_CYCLE_START: 'SCHEDULED_CYCLE_START',
  ...BSD_CALENDAR_EVENTS_ACTIONS,
}

export const BSD_CALENDAR_EVENTS_TO_NAME = {
  [BSD_CALENDAR_EVENTS.WEEKEND]: 'Weekend',
  [BSD_CALENDAR_EVENTS.HOLIDAY]: 'Holiday',
  [BSD_CALENDAR_EVENTS.SCHEDULED_COLLECTION]: 'Scheduled Collection',
  [BSD_CALENDAR_EVENTS.SCHEDULED_CYCLE_START]: 'Scheduled Cycle Start',
  [BSD_CALENDAR_EVENTS.SKIP_CYCLE_START]: 'Skip Cycle Start',
  [BSD_CALENDAR_EVENTS.SKIP_COLLECTION]: 'Skip Collection',
  [BSD_CALENDAR_EVENTS.FORCE_CYCLE_START]: 'Force Cycle Start',
  [BSD_CALENDAR_EVENTS.FORCE_COLLECTION]: 'Force Collection',
  [BSD_CALENDAR_EVENTS.UN_SKIP]: 'Un-skip', // might need another one, one for collection and one for cycle start
}

export const INCL_TX_STATUS_SUBMISSION_KEY_TITLE = {
  [STATUSES.ACCEPTED]: capitalize(STATUSES.ACCEPTED),
  [STATUSES.MATCHED]: capitalize(STATUSES.MATCHED),
  [STATUSES.AMENDED]: capitalize(STATUSES.AMENDED),
  [STATUSES.REJECTED]: capitalize(STATUSES.REJECTED),
}

export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
export const currencies = Object.keys(CURRENCY_CODE)
export const transactionTypes = Object.values(TRANSACTION_TYPE_TO_NAME)
export const transactionStates = Object.values(TRANSACTION_STATE_TO_NAME)
export const transactionLineTransactions = ['Invoice', 'Credit Note', 'Commission Invoice']
export const HIGH_PRIORITY_COUNTRIES_ALPHA2 = ['GB']

const constants = {
  APP_PREFIX,
  API_URL,
  USER_AUTH_STORAGE_KEYS,
  ROUTE_URL,
  TRANSACTION_STATE_TO_CSS,
  KEYS,
}

export default constants

export const FEED_SENT = 'sent'
export const FEED_RECEIVED = 'received'

export const PAYMENT_PLATFORMS = {
  DIRECT_DEBIT_UK: 'DIRECT_DEBIT_UK',
  E_WALLET: 'E_WALLET',
}
export const PAYMENT_GATEWAY_PLATFORM = {
  [PAYMENT_PLATFORMS.DIRECT_DEBIT_UK]: 'UK Direct Debit',
  [PAYMENT_PLATFORMS.E_WALLET]: 'E-Wallet',
}

export const INVOICE_STATUS = {
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  REMITTED: 'REMITTED',
}

export const ACCOUNT_ROLE = {
  BUYER: 'BUYER',
  SELLER: 'SELLER',
  PROCESSING_ENTITY: 'PROCESSING_ENTITY',
}

export const ACCOUNT_ROLE_TO_NAME = {
  [ACCOUNT_ROLE.BUYER]: 'Buyer',
  [ACCOUNT_ROLE.SELLER]: 'Seller',
  [ACCOUNT_ROLE.PROCESSING_ENTITY]: 'Processing Entity',
}

export const FILE_DATA_DELIVERY_COMMIT_OPTIONS = {
  FULL: 'FULL',
  PARTIAL: 'PARTIAL',
}

export const INVOICE_TAGS = {
  /**
   * @type {'POSSIBLE_ENHANCED_DUPLICATE'}
   */ POSSIBLE_ENHANCED_DUPLICATE: 'POSSIBLE_ENHANCED_DUPLICATE',
  /**
   * @type {'POSSIBLE_DUPLICATE_WITH_SAME_AMOUNT'}
   */ POSSIBLE_DUPLICATE_WITH_SAME_AMOUNT: 'POSSIBLE_DUPLICATE_WITH_SAME_AMOUNT',
  /**
   * @type {'POSSIBLE_DUPLICATE_WITH_DIFFERENT_AMOUNT'}
   */ POSSIBLE_DUPLICATE_WITH_DIFFERENT_AMOUNT: 'POSSIBLE_DUPLICATE_WITH_DIFFERENT_AMOUNT',
  /**
   * @type {'AMOUNT_ABOVE_THRESHOLD'
   */ AMOUNT_ABOVE_THRESHOLD: 'AMOUNT_ABOVE_THRESHOLD',
  /**
   * @type {'PENDING_COMMERCIAL_LINK'}
   */ PENDING_COMMERCIAL_LINK: 'PENDING_COMMERCIAL_LINK',
  /**
   * @type {'SUSPENDED_COMMERCIAL_LINK'}
   */ SUSPENDED_COMMERCIAL_LINK: 'SUSPENDED_COMMERCIAL_LINK',
  /**
   * @type {'ARCHIVED_COMMERCIAL_LINK'}
   */ ARCHIVED_COMMERCIAL_LINK: 'ARCHIVED_COMMERCIAL_LINK',
}
/** @typedef {EnumType<INVOICE_TAGS>} InvoiceTag */

export const INVOICE_TAGS_READABLE = {
  [INVOICE_TAGS.POSSIBLE_ENHANCED_DUPLICATE]:
    'Duplicate transaction in file for the same Buyer/Seller/Due date/Transaction type/Booking Ref/Currency. Unable to Load',
  [INVOICE_TAGS.POSSIBLE_DUPLICATE_WITH_SAME_AMOUNT]: 'Duplicate(s) invoices with same Booking Ref/Amount exist(s)',
  [INVOICE_TAGS.POSSIBLE_DUPLICATE_WITH_DIFFERENT_AMOUNT]:
    'Duplicate(s) invoices with same Booking Ref but different Amount exist(s)',
  [INVOICE_TAGS.AMOUNT_ABOVE_THRESHOLD]: 'Amount above threshold',
  [INVOICE_TAGS.PENDING_COMMERCIAL_LINK]: 'Pending Commercial Link',
  [INVOICE_TAGS.SUSPENDED_COMMERCIAL_LINK]: 'Suspended Commercial Link',
  [INVOICE_TAGS.ARCHIVED_COMMERCIAL_LINK]: 'Archived Commercial Link',
}

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'
// Used for exports
export const DEFAULT_READABLE_DATE_FORMAT = 'DD/MM/YYYY'
export const DEFAULT_DATETIME_FORMAT = 'DD/MM/YYYY HH:mm'

export const SETTLEMENT_STATUS_VALUES = {
  DUE: 'DUE',
  COLLECTION_PENDING: 'COLLECTION_PENDING',
  COLLECTION_COMPLETE: 'COLLECTION_COMPLETE',
  COLLECTION_FAILED: 'COLLECTION_FAILED',
  DISBURSEMENT_PENDING: 'DISBURSEMENT_PENDING',
  DISBURSEMENT_FAILED: 'DISBURSEMENT_FAILED',
  CYCLE_COMPLETE: 'CYCLE_COMPLETE',
  NO_COLLECTION_REQUIRED: 'NO_COLLECTION_REQUIRED',
}

export const SETTLEMENT_STATUS_VALUES_TO_NAME = {
  [SETTLEMENT_STATUS_VALUES.DUE]: 'Due',
  [SETTLEMENT_STATUS_VALUES.COLLECTION_PENDING]: 'Collection pending',
  [SETTLEMENT_STATUS_VALUES.COLLECTION_COMPLETE]: 'Collection complete',
  [SETTLEMENT_STATUS_VALUES.COLLECTION_FAILED]: 'Collection failed',
  [SETTLEMENT_STATUS_VALUES.DISBURSEMENT_PENDING]: 'Payment pending',
  [SETTLEMENT_STATUS_VALUES.DISBURSEMENT_FAILED]: 'Payment failed',
  [SETTLEMENT_STATUS_VALUES.CYCLE_COMPLETE]: 'Complete',
  [SETTLEMENT_STATUS_VALUES.NO_COLLECTION_REQUIRED]: 'No collection required',
}

export const INVOICE_ROLE = {
  TARGET: 'TARGET',
  SOURCE: 'SOURCE',
  PROCESSING_ENTITY: 'PROCESSING_ENTITY',
}

export const DEFAULT_SNACKBAR_TIMEOUT = 3000
export const ERROR_SNACKBAR_TIMEOUT = 7000
export const SUCCESS_SNACKBAR_TIMEOUT = DEFAULT_SNACKBAR_TIMEOUT

export const USER_ROLES = {
  ADMIN: 'ADMIN',
  SUPERADMIN: 'SUPERADMIN',
  NORMAL: 'NORMAL',
}

export const isProduction = window.location.host === 'app.travelledger.org'

export const REQUIRED_PASSWORD_STRENGTH = 3

export const PAYMENT_FOR = {
  /** @type {'buyer collection'} */
  BUYER_COLLECTION: 'buyer collection',
  /** @type {'seller refund'} */
  SELLER_REFUND: 'seller refund',
  /** @type {'seller disbursement'} */
  SELLER_DISBURSEMENT: 'seller disbursement',
  /** @type {'buyer disbursement'} */
  BUYER_DISBURSEMENT: 'buyer disbursement',
  /** @type {'instant payment'} */
  INSTANT_PAYMENT: 'instant payment',
}
/** @typedef {typeof PAYMENT_FOR[keyof PAYMENT_FOR]} PaymentFor */

export const DASHBOARDS = {
  SETTLEMENT_OVERVIEW: 'settlement-overview',
}

export const TASK_FREQUENCY_TYPES = {
  MONTHLY: 'MONTHLY',
  WEEKLY: 'WEEKLY',
  NONE: 'NONE',
}

export const SUBSCRIPTION_STATUS = {
  CANCELLED: 'cancelled',
  UNPAID: 'unpaid',
  ACTIVE: 'active',
}

export const SUBSCRIPTION_TYPES = {
  BASIC: 'BASIC',
  PRO: 'PRO',
  PREMIUM: 'PREMIUM',
}

export const LOCALES = {
  ENGLISH: 'en-GB',
  FRENCH: 'fr',
  GERMAN: 'de',
}

export const LANGUAGES = [
  { name: 'English', locale: LOCALES.ENGLISH },
  { name: 'Français', locale: LOCALES.FRENCH },
  { name: 'Deutsche', locale: LOCALES.GERMAN },
]

export const QUICK_VIEWS = {
  BUYER_CURRENT_WEEK: 'Buyer - Current Week',
  BUYER_ALL_DUE: 'Buyer - All Due',
  BUYER_PAST: 'Buyer - Past',
  SELLER_CURRENT_WEEK: 'Seller - Current Week',
  SELLER_ALL_DUE: 'Seller - All Due',
  SELLER_PAST: 'Seller - Past',
  PE_CURRENT_WEEK: 'PE - Current Week',
  PE_ALL_DUE: 'PE - All Due',
  PE_PAST: 'PE - Past',
}

export const REQUEST_TIMEOUT = 180000
export const COMPANY_STATUS = {
  LIVE: 'LIVE',
  SUSPENDED: 'SUSPENDED',
  ARCHIVED: 'ARCHIVED',
}

export const SCHEDULE_AVAILABLE_DATE_FORMATS = {
  'DDMMMYY': 'DDMMMYY',
  'DD-MM-YY': 'DD-MM-YY',
  'DD/MM/YY': 'DD/MM/YY',
}

export const SCHEDULE_AVAILABLE_ENCAPSULATION_OPTIONS = {
  NONE: 'None',
  DOUBLE_SPEECH_MARK: 'Double Speech Mark',
}

// @travel-ledger/type-constants ->> enum DecimalSeparator
export const SCHEDULE_AVAILABLE_DECIMAL_SEPARATOR_CHAR = {
  FULL_STOP: 'Full stop/period',
  COMMA: 'Comma',
}

export const DATA_DELIVERY_STATUSES = {
  LOADED: 'LOADED',
  REJECTED: 'REJECTED',
  IMPORT_STARTED: 'IMPORT_STARTED',
  FILE_PARSED: 'FILE_PARSED',
  DUPLICATES_CHECKED: 'DUPLICATES_CHECKED',
  INVOICES_CREATED: 'INVOICES_CREATED',
  SCHEDULE_ENTRIES_CREATED: 'SCHEDULE_ENTRIES_CREATED',
  TAGS_CREATED: 'TAGS_CREATED',
  AUDIT_LOG_CREATED: 'AUDIT_LOG_CREATED',
  INVOICE_QUERIES_QUEUED: 'INVOICE_QUERIES_QUEUED',
  INVOICE_QUERIES_EXECUTED: 'INVOICE_QUERIES_EXECUTED',
  ERRORS_CHECKED: 'ERRORS_CHECKED',
}

export const DATA_DELIVERY_STATUSES_TO_PERCENTAGES = {
  [DATA_DELIVERY_STATUSES.IMPORT_STARTED]: 20,
  [DATA_DELIVERY_STATUSES.FILE_PARSED]: 40,
  [DATA_DELIVERY_STATUSES.DUPLICATES_CHECKED]: 50,
  [DATA_DELIVERY_STATUSES.INVOICE_QUERIES_QUEUED]: 60,
  [DATA_DELIVERY_STATUSES.INVOICES_CREATED]: 60,
  [DATA_DELIVERY_STATUSES.INVOICE_QUERIES_EXECUTED]: 70,
  [DATA_DELIVERY_STATUSES.SCHEDULE_ENTRIES_CREATED]: 70,
  [DATA_DELIVERY_STATUSES.ERRORS_CHECKED]: 80,
  [DATA_DELIVERY_STATUSES.TAGS_CREATED]: 80,
  [DATA_DELIVERY_STATUSES.AUDIT_LOG_CREATED]: 90,
}

// do note that the settlement schedule view only uses a checkbox to
// choose between strategies, so adding a new strategy will require a
// change to the settlement schedule view
export const CYCLE_START_STRATEGY = {
  CLOSEST_TO_SETTLEMENT: 'CLOSEST_TO_SETTLEMENT',
  EARLIEST: 'EARLIEST',
}

export const EWALLET_ONBOARD_STATUS = {
    PENDING: 'PENDING',
    ACTION_REQUIRED: 'ACTION_REQUIRED',
    REJECTED: 'REJECTED',
    DELETED: 'DELETED',
    COMPLETED: 'COMPLETED'
}

export const NIUM_ONBOARD_STATUS = {
  INITIATED: 'INITIATED',
  IN_PROGRESS: 'IN_PROGRESS',
  ACTION_REQUIRED: 'ACTION_REQUIRED',
  RFI_REQUESTED: 'RFI_REQUESTED',
  RFI_RESPONDED: 'RFI_RESPONDED',
  COMPLETED: 'COMPLETED',
  REJECT: 'REJECT',
  ERROR: 'ERROR',
  EXPIRED: 'EXPIRED',
  CLOSED: 'CLOSED',
}

export const NIUM_STAKEHOLDER_TYPE = {
  CORPORATE: 'CORPORATE',
  INDIVIDUAL: 'INDIVIDUAL',
}

export const NIUM_RFI_TYPE = {
  CORPORATE: 'corporate',
  APPLICANT: 'applicant',
  STAKEHOLDER: 'stakeholder',
}

export const NIUM_RFI_TEMPLATE_TYPE = {
  DOCUMENT: 'document',
  DATA: 'data',
}

export const EWALLET_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
}

export const EWALLET_PROVIDER = {
  NIUM_UK: "NIUM UK",
  NIUM_EU: "NIUM EU",
  REVOLUT: "REVOLUT",
}

export const EWALLET_REGION = {
  UK: 'UK',
  EU: 'EU',
}

// copied and adapted from @travel-ledger/type-constants
export const TransType = {
  Balance: 'BALANCE',
  Deposit: 'DEPOSIT',
  Commission: 'COMMISSION',
  ExtraPayment: 'EXTRA_PAYMENT',
  Refund: 'REFUND',
  Services: 'SERVICES',
}
// copied and adapted from @travel-ledger/type-constants
export const TransTypeDescription = {
  [TransType.Balance]: 'Balance',
  [TransType.Deposit]: 'Deposit',
  [TransType.Commission]: 'Commission',
  [TransType.ExtraPayment]: 'Extra Payment',
  [TransType.Refund]: 'Refund',
  [TransType.Services]: 'Services',
  [TransType.InstantPayment]: 'Instant Payment',
}